import { template as template_748c004bbbbc4542bd355b5f5becf9be } from "@ember/template-compiler";
const FKControlMenuContainer = template_748c004bbbbc4542bd355b5f5becf9be(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
