import { template as template_b3c187988c0043748927d7b7abfae7b4 } from "@ember/template-compiler";
const FKLabel = template_b3c187988c0043748927d7b7abfae7b4(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
