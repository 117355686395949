import { template as template_67d6a104afde4d97b206fe69b156e957 } from "@ember/template-compiler";
const FKText = template_67d6a104afde4d97b206fe69b156e957(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
