import { template as template_148496ab94de464e9e0525182504d7af } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BulkSelectToggle = template_148496ab94de464e9e0525182504d7af(`
  <DButton
    class="bulk-select"
    @action={{@bulkSelectHelper.toggleBulkSelect}}
    @icon="list"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BulkSelectToggle;
